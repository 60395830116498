<template>
  <v-timeline side="start">
    <v-timeline-item v-for="(item, i) in items" :key="i" :dot-color="item.color" size="x-small" >
      <template v-slot:opposite>
        <p>{{ item.status }}</p> 
      </template>
      <div :dot-color="item.color">
        <v-card-text>
          {{ item.date }}
        </v-card-text>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { ref, watch } from "vue";

export default {
  props: {
    message: Object
  },

  setup(props) {
    const items = ref([]);

    // Следим за изменениями свойства message
    watch(() => props.message, (newVal, oldVal) => {
console.log(oldVal);
      if (newVal) {
        items.value = [
          {
            color: 'blue',
            icon: 'mdi-home',
            date: newVal.createdAt,
            status: 'Добавлено сообщение',
          }
        ];
      }
    });

    return { items };
  }
}
</script>