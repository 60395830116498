<template>
  <v-container>
    <v-col class="mb-4 custom-bg__color">
      <v-row>
        <v-col class="pa-5">
          <v-btn @click="goBack">
            <v-icon>mdi-chevron-left</v-icon>
            вернуться
          </v-btn>

        </v-col>
      </v-row>
      <v-card-title class="mt-5 mb-5 custom-title__card">
        <v-row>
          <v-col cols="12" md="12" sm="5" class="">
            <v-chip class="custom-text__head" :color="getStatusColor(message.status)">
              {{ getStatusText(message.status) }}
            </v-chip>
          </v-col>
          <v-col>
            <div class="custom-text__head font-weight-bold ml-3">
              <!--              {{ address.region }}, - {{ address.district }}, с {{ address.city }}, {{ address.street }},-->
              <!--              {{ address.building }}-->
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-2">
            <div class="custom-text__head2  d-flex">
              <span class="grey--text ml-3 text-primary">#{{ message.id }}</span>
              <div class="mb-2 ml-3 ">Номер УИК:<span class="text-primary">{{ message.numberUIK }}</span></div>
              <div class="mb-2 ml-3 ">Избирательная кампания: Единый День Голосования 2024
                года
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <!--- Блок -->
      <v-row>
        <v-col class="custom-col__size mb-5" cols="12" lg="11">
          <v-card class="pa-5 custom-card__width" rounded="0">
            <v-row>
              <v-col cols="12" md="12" sm="12">

                <v-row>
                  <v-col cols="12" md="1" sm="1">
                    <div class="icon-circle">
                      <v-img :src="require('@/assets/img/general.png')" height="20"></v-img>
                    </div>
                  </v-col>
                  <v-col class="pa-0">
                    <v-card-text class="custom-text__title">
                      Общие сведения - <span class="text-caption text-grey">
                        {{ message.createdAt }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-text class="custom-text__message text-caption font-weight-light">
                  <v-tooltip v-model="show" location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn class="ma-2" v-if="isAuthenticated()" @click="startEditing" icon v-bind="props">
                        <v-icon color="info">
                          mdi-pen
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Редактировать сообщение</span>
                  </v-tooltip>
                  <template v-if="!isEditing">
                    {{ message.message }}
                  </template>
                  <template v-else>
                    <v-text-field v-model="editableMessage" @input="onEdit"
                                  label="Редактировать сообщение"></v-text-field>
                    <v-btn class="mt-2" :disabled="!isDirty" @click="saveChanges">Сохранить</v-btn>
                  </template>
                </v-card-text>
                <v-row>
                  <div v-for="(file, index) in message.files" :key="index" class="d-inline-block">
                    <v-img :src="file" class="ma-2" width="100" @click="showImage(file)"></v-img>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <template v-if="isAuthenticated()">
            <v-card class="pa-5 custom-card__width custom-yellow-background" rounded="0">

              <v-row>
                <v-col cols="12" md="1" sm="1">
                  <div class="icon-circle">
                    <v-img :src="require('@/assets/img/logo.png')" height="20"></v-img>
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <v-card-text class="text-add__coment">
                    Добавить новый комментарий
                  </v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-textarea v-model="commentText" @input="validateComment" auto-grow class="custom-text__area"
                              outlined variant="outlined" rows="4" dense filled></v-textarea>

                  <v-file-input v-model="files" variant="outlined" :prepend-icon="null" class="custom-text__area mt-5"
                                outlined small-chips label="Выберите файлы или перетащите их в эту область"></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="10">
                  <v-select class="custom-text__file" variant="outlined" outlined :items="allStatuses"
                            label="Изменить статус" v-model="selectedStatus" @update:modelValue="statusChanged"></v-select>
                </v-col>
                <v-col>
                  <v-card-actions class="justify-end custom-btn__mob">
                    <v-btn @click="submitComment" :disabled="!isFormValid" color="primary" rounded="xl"
                           variant="elevated">Добавить
                      комментарий</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <v-card v-for="(comment, index) in message.comments" :key="index" class="pa-5 custom-card__width" rounded="0">
            <v-row>
              <v-col cols="12" md="12" sm="12">

                <v-row>
                  <v-col cols="12" md="1" sm="1">
                    <div class="icon-circle">
                      <v-img :src="require('@/assets/img/logo.png')" height="20"></v-img>
                    </div>
                  </v-col>
                  <v-col class="pa-0">
                    <v-card-text class="custom-text__title">
                      Автор комментария {{ comment.author }} <span class="text-caption text-grey">
                        - {{ comment.createdAt }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-text class="custom-text__message text-caption font-weight-light">
                      {{ comment.content }}
                    </v-card-text>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-5 custom-card__width" rounded="0">

            <v-row>
              <v-col cols="12" md="1" sm="1">
                <div class="icon-circle">
                  <v-img :src="require('@/assets/img/history.png')" height="20"></v-img>
                </div>
              </v-col>
              <v-col class="pa-0">
                <v-card-text class="custom-text__title">
                  История
                </v-card-text>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6">
                <UserTimeline :message="message"></UserTimeline>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!---Конец блока-->

    </v-col>
  </v-container>

  <v-dialog v-model="showConfirm" persistent max-width="400px">
    <v-card>
      <v-card-title>Подтверждение</v-card-title>
      <v-card-text>Подтвердите смену статуса на {{ newStatusText }}.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelChange">Отмена</v-btn>
        <v-btn :disabled="isLoadingConfirmChange" color="blue darken-1" text @click="confirmChange">{{
            LoadingConfirmBtnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" width="600">
    <v-img :src="selectedImage" @click.self="dialog = false"></v-img>
  </v-dialog>

  <v-dialog v-model="showDialogConfirm" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5">Подтверждение</v-card-title>
      <v-card-text>Вы уверены, что хотите сохранить изменения?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelEditing">Отмена</v-btn>
        <v-btn color="green darken-1" text @click="confirmSave">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserTimeline from '@/components/UserTimeline.vue'
import { statusMixin } from "@/mixins/statusMixin";
import { useAuth } from '@/composables/useAuth';


export default {
  mixins: [statusMixin],
  data() {
    return {
      show: false,
      isEditing: false,
      isLoadingConfirmChange: false,
      LoadingConfirmBtnText: 'Подтвердить',
      editableMessage: '',
      originalMessage: '',
      isDirty: false,
      dialog: false, // Контроль отображения диалога
      showDialogConfirm: false, // диалог редактирование сообщения
      selectedImage: '', // URL выбранного изображения
      commentText: '', // Для текста комментария
      files: [], // Для хранения файлов
      message: [],
      showConfirm: false,
      oldStatus: '',
      newStatus: '',
      oldStatusText: '',
      newStatusText: '',
      usercomment: '',
      selectedStatus: null,
      address: {
        region: "Пермский край",
        district: "Пермский край",
        city: "Уинское",
        street: "ул Ленина",
        building: "д 26"
      },
      isFormValid: false
    };
  },

  methods: {
    async confirmSave() {
      // Формирование данных для отправки
      const data = {
        message: this.editableMessage, // Поле с обновленным текстом сообщения
        messageId: this.message.id,
      };

      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/messages/edit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error('Сервер вернул ошибку при отправке данных');
        }

        const responseData = await response.json();
        console.log('Данные успешно обновлены:', responseData);
        // Обновите состояние приложения в случае успеха
        this.message.message = this.editableMessage;
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        // Обработка ошибок, например, показ сообщения пользователю
      }

      // Сброс состояния независимо от результата
      this.isEditing = false;
      this.isDirty = false;
      this.showDialogConfirm = false;
    },
    startEditing() {
      if (this.isEditing) {
        // Если пользователь в режиме редактирования, проверяем, были ли сделаны изменения
        if (!this.isDirty) {
          this.cancelEditing();
        }
      } else {
        this.isEditing = true;
        this.editableMessage = this.message.message;
        this.originalMessage = this.message.message;
      }
    },
    saveChanges() {
      if (this.isDirty) {
        this.showDialogConfirm = true;
      }
    },
    cancelEditing() {
      this.isEditing = false;
      this.showDialogConfirm = false;
      // Не нужно менять `editableMessage` здесь, так как оно уже синхронизировано с input
      // и будет возвращено к исходному состоянию при следующем входе в режим редактирования
      this.editableMessage = this.originalMessage;
    },
    onEdit() {
      this.isDirty = this.editableMessage !== this.originalMessage; // Проверяем, были ли изменения
    },
    validateComment() {
      this.isFormValid = this.commentText.trim().length > 0;
    },
    showImage(imageUrl) {
      this.selectedImage = imageUrl;
      this.dialog = true;
    },
    submitComment() {
      const formData = new FormData();

      if (this.isFormValid) {
        formData.append('comment', this.commentText);
        if (this.files && this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            formData.append(`file${i}`, this.files[i]);
          }
        }
      } else {
        alert('Комментарий не может быть пустым');
      }

      formData.append('messageId', this.message.id);

      fetch(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/comment/add`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: formData,
      })
          .then(response => {
            if (!response.ok) {
              throw new Error('Сервер вернул ошибку при отправке комментария');
            }
            return response.json();
          })
          .then(data => {
            console.log('Комментарий успешно добавлен', data);
            this.fetchMessageDetails();
            this.commentText = '';
            this.files = [];
          })
          .catch(error => {
            console.error('Ошибка при отправке комментария:', error);
          });
    },
    statusChanged(newStatusValue) {
      this.selectedStatus = newStatusValue;
      this.newStatusText = this.getStatusText(newStatusValue);
      this.showConfirm = true;
    },
    async confirmChange() {
      this.isLoadingConfirmChange = true;
      this.LoadingConfirmBtnText = 'ждите...'
      const messageId = this.message.id;
      const newStatus = this.selectedStatus;
      const token = localStorage.getItem('authToken');

      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/message/update/status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            status: newStatus,
            messageId: messageId,
          }),
        });

        if (!response.ok) {
          throw new Error('Проблема с запросом к серверу');
        }

        console.log('Статус успешно изменен');
        this.showConfirm = false;

        this.message.status = newStatus;
      } catch (error) {
        console.error('Ошибка при обновлении статуса:', error);
      } finally {
        this.isLoadingConfirmChange = false;
        this.LoadingConfirmBtnText = 'Подтвердить'
      }
    },
    cancelChange() {
      this.selectedStatus = this.prevStatus;
      this.showConfirm = false;
    },
    fetchMessageDetails() {
      const url = `${process.env.VUE_APP_BACKEND_API_BASE_URL}/message/${this.$route.params.messageID}`;
      fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Сервер вернул ошибку');
            }
            return response.json();
          })
          .then(data => {
            this.message = data;
          })
          .catch(error => console.error('Ошибка получения данных:', error));
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.fetchMessageDetails();
    this.validateComment();
  },
  components: {
    UserTimeline
  },
  setup() {
    const { isAuthenticated, user } = useAuth();

    return {

      isAuthenticated,
      user,
    };
  },
};
</script>


<style scoped>
.icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-bg__color {
  background-color: white;
}

.icon-circle img {
  top: 10px;
  height: 66%;
}

.custom-text__message {
  font-size: clamp(4px, 2vw, 15px);
}

.custom-divider {
  padding: 0px !important;
}

.custom-text__area {
  margin-left: 80px;
  background-color: white;
  border: none;
}

.custom-yellow-background {
  background-color: #f3efcc;
  /* HEX код вашего оттенка желтого */
}

::v-deep .v-input__details {
  min-height: 0px;
  padding-top: 0px;
}

.custom-text {
  margin-left: 75px;
}


.custom-text__file {
  margin-left: 80px;
  background-color: white;
  border: none;
}

.custom-title__card {
  margin-left: 40px;
}

.custom-text__head {
  font-size: clamp(20px, 2vw, 18px);
  white-space: normal;
  word-wrap: break-word;
}

.custom-text__head2 {
  font-size: clamp(5px, 2vw, 12px);
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .v-card .custom-card__width {
  width: 1000px;
}

::v-deep .v-chip__content {
  font-size: clamp(10px, 2vw, 15px);
}

::v-deep .v-col-md-1 {
  max-width: 7.66666%;
}

.custom-card__width {
  margin-left: 60px;
}

@media (min-width: 1920px) and (max-width: 3072px) {

  ::v-deep .v-col-md-1 {
    max-width: 5.333333%;
  }
}

@media (min-width: 961px) and (max-width: 1280px) {

  ::v-deep .v-col-md-1 {
    max-width: 11.333333%;
  }
}

@media (min-width: 412px) and (max-width: 960px) {
  .custom-text__area {
    margin-left: 0px;
  }

  .custom-card__width {
    margin-left: 0px;
  }

  .custom-text__file {
    margin-left: 0px;
  }

  .custom-title__card {
    margin-left: 0px;
  }

  ::v-deep .v-col-md-1 {
    max-width: 16.333333%;
  }
}

@media (min-width: 375px) and (max-width: 412px) {

  .custom-card__width {
    margin-left: 0px;
  }

  .icon-circle {
    width: 10px;
    height: 10px;
  }

  .icon-circle img {
    width: 5px;
    height: 5px;
  }

  .custom-text__area {
    margin-left: 0px;
  }

  .custom-text__file {
    margin-left: 0px;
  }

  .custom-text__head {
    font-size: 0.6rem;
  }

  .custom-col__size {
    margin-left: 10px
  }

  .custom-title__card {
    margin-left: 0px;
  }
}

@media (min-width: 250px) and (max-width: 375px) {

  .custom-card__width {
    margin-left: 0px;
  }

  .icon-circle {
    width: 5px;
    height: 5px;
  }

  .icon-circle img {
    width: 5px;
    height: 5px;
  }

  .custom-text__area {
    margin-left: 0px;
  }

  .custom-text__file {
    margin-left: 0px;
  }

  .custom-text__head {
    font-size: 0.4rem;
  }

  .custom-col__size {
    margin-left: 5px
  }

  .custom-title__card {
    margin-left: 0px;
  }

  ::v-deep .v-chip__content {
    font-size: 8px;
  }

  .custom-text__head2 {
    font-size: 8px;
  }
}
</style>
