<template>
  <v-app class="page-background">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
/* global ym */
export default {
  name: 'App',
  mounted() {
    ((m, e, t, r, i, k, a) => {
      m[i] = m[i] || function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
      m[i].l = 1 * new Date();
      k = e.createElement(t);
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(96783538, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });
  }
}
</script>




<style>

.page-background {
  background-color: #f5f5f5;
  /* Серый цвет */
}
</style>