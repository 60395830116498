<template>
  <v-container fluid>
    <v-row>
      <!-- <v-col md="5">
        <v-text-field class="custom-text__file" variant="outlined" outlined dense
          placeholder="Поиск по тексту сообщения"></v-text-field>
      </v-col> -->
      <v-col md="5" class=" justify-start font-weight-bold">
        <v-text class="custom-text__file"
        > Единый День Голосования 2024</v-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      electionEvents: [
        ' Единый День Голосования 2024',
        'Другой выбор',
      ],
      selectedEvent: ' Единый День Голосования 2024',
    };
  },
  methods: {
    fetchElectionEvents() {

    }
  }
};
</script>

<style scoped>
.custom-text__file {
  white-space: nowrap;
  border: none;
  font-size: clamp( 15px, 2vw, 20px);
}

::v-deep .v-input__details {
  min-height: 0px;
  padding-top: 0px;
}
</style>