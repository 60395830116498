import { ref, computed } from 'vue';

export function useStatus() {
    const statusMappings = ref({
        INFORMATION: { text: "Информационное сообщение", color: "#56b07e" },
        VIOLATION: { text: "Нарушение", color: "#eb4954" },
        FAKE: { text: "Фейк", color: "#707070" },
        PROCESSING: { text: "В обработке", color: "#a7a7a7" },
        RESOLVED: { text: "Проблема решена", color: "#9edcbe" },
        CANCEL: { text: "Сообщение отклонено", color: "#d1d149" },
    });

    const getStatusText = (statusCode) => {
        return statusMappings.value[statusCode] ? statusMappings.value[statusCode].text : 'Неизвестный статус';
    };

    const getStatusColor = (statusCode) => {
        return statusMappings.value[statusCode] ? statusMappings.value[statusCode].color : 'grey';
    };

    const allStatuses = computed(() => {
        return Object.keys(statusMappings.value).map(key => ({
            value: key,
            title: statusMappings.value[key].text,
            statusColor: statusMappings.value[key].color
        }));
    });

    return { statusMappings, getStatusText, getStatusColor, allStatuses };
}
