    export const statusMixin = {
        data() {
            return {
                statusMappings: {
                    INFORMATION: { text: "Информационное сообщение", color: "#56b07e" },
                    VIOLATION: { text: "Нарушение", color: "#eb4954" },
                    FAKE: { text: "Фейк", color: "#707070" },
                    PROCESSING: { text: "В обработке", color: "#a7a7a7" },
                    RESOLVED: { text: "Проблема решена", color: "#9edcbe" },
                    CANCEL: { text: "Сообщение отклонено", color: "#d1d149" },
                }
            };
        },
        methods: {
            getStatusText(statusCode) {
                return this.statusMappings[statusCode] ? this.statusMappings[statusCode].text : 'Неизвестный статус';
            },
            getStatusColor(statusCode) {
                return this.statusMappings[statusCode] ? this.statusMappings[statusCode].color : 'grey';
            },
            getSpecificStatus(statusValue) {
                return this.statusMappings[statusValue] || null;
              },
        },
        computed: {
            allStatuses() {
                return Object.keys(this.statusMappings).map(key => ({
                    value : key,
                    title: this.statusMappings[key].text,
                    statusColor: this.statusMappings[key].color
                }));
            },
        },
    };