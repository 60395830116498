<template>
    <v-container class="text-message__all" fluid>
        <v-col cols="12">
            <v-text>
                <div class="text-h5 font-weight-bold">
                    Отправить сообщение
                </div>
                <div class="text-blue font-weight-light mt-4">
                    Поля помаеченные <span class="text-red">*</span> обязательные для заполнения
                </div>
            </v-text>
        </v-col>
        <v-row class="mt-10 ml-0">
            <v-col class="my-col" cols="12" sm="12" md="4">
                <v-text>
                    <div class="text-h7 font-weight-bold">
                        Персональные данные
                    </div>
                </v-text>
                <v-text>
                    <div class="mt-10">
                        ФИО<span class="text-red"> *</span>
                    </div>
                </v-text>
                <v-text-field color="blue" class="mt-1" label="Не публикуется без Вашего согласия"
                    variant="outlined"></v-text-field>

                <v-checkbox class="mt-2" color="info">
                    <template v-slot:label>
                        <div class="custom-label__info">Разрешаю отображать мои ФИО на сайте</div>
                    </template>
                </v-checkbox>

                <v-text>
                    <div class="mt-8">
                        Email<span class="text-red"> *</span>
                    </div>
                </v-text>
                <v-text-field color="blue" class="mt-1" placeholder="your@mail.ru" variant="outlined"></v-text-field>


                <v-text>
                    <div class="mt-5">
                        Прочие контактные данные<span class="text-red"> *</span>
                        <br>
                        <span class="font-weight-light text-caption mt-3">не публикуется на сайте</span>
                    </div>
                </v-text>
                <v-text-field color="blue" class="mt-1" placeholder="Не публикуется без Вашего согласия"
                    variant="outlined"></v-text-field>

                <v-checkbox class="mt-3" color="info">
                    <template v-slot:label>
                        <div class="custom-label__info">Даю согласие <a class="custom-label" href="/customAgreement"
                                target="_blank">на обработку моих персональных данных</a>
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col class="my-col" cols="12" sm="12" md="4">

                <v-text>
                    <div class="text-h7 font-weight-bold">
                        Данные об избирательном участке
                    </div>
                </v-text>
                <div>
                    <v-text>
                        <div class="mt-11">
                            Регион<span class="text-red"> *</span>
                        </div>
                    </v-text>
                    <v-select v-model="selectedRegion" :items="regions" item-text="title" item-value="value"
                        placeholder="Выберите регион" variant="outlined" clearable color="blue"
                        return-object></v-select>
                </div>

                <template v-if="selectedRegion">
                    <v-text>
                        <div class="mt-5">
                            Номер УИК<span class="text-red"> *</span>
                            <br>
                            <span class="font-weight-light text-caption mt-3">Начните вводить номер и выберите
                                вариант из списка</span>
                        </div>
                    </v-text>
                    <v-text-field placeholder="4504029212858"></v-text-field>
                </template>

            </v-col>
            <v-col class="my-col" cols="12" sm="12" md="4">

                <v-text>
                    <div class="text-h7 font-weight-bold">
                        Информационное сообщение или сообщение о нарушении
                    </div>
                </v-text>
                <v-text>
                    <div class="mt-11">
                        Характер сообщения
                    </div>
                </v-text>
                <v-radio-group>
                    <v-radio color="blue" class="custom-label-color" label="Информационное сообщение (нарушений нет)"
                        value="one"></v-radio>
                    <v-radio color="blue" label="Сигнал о нарушении" value="two"></v-radio>
                </v-radio-group>


                <v-text>
                    <div class="mt-15">
                        Сообщение <span class="text-red"> *</span>
                    </div>
                </v-text>
                <v-textarea color="blue" auto-grow class="custom-text__area" outlined variant="outlined" rows="4" dense
                    filled></v-textarea>

                <v-file-input color="blue" variant="outlined" :prepend-icon="null" class="custom-text__area mt-5"
                    outlined small-chips label="Выберите файлы или перетащите их в эту область"></v-file-input>

            </v-col>

        </v-row>

        <v-row class="ml-0">
            <v-col cols="12" md="3">

                <v-text>
                    <div class="mt-8 font-weight-bold">
                        Нарушения до дня голосования
                    </div>
                </v-text>


                <v-checkbox color="info" class="mt-9">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения при сборе подписей</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение правил печатной и наружной агитации
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox class="mt-3" color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение правил агитации в СМИ, кроме админресурса</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Принуждение, подкуп избирателей</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения при досрочном голосовании</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения, связанные с «мобильным избирателем»</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение прав членов комиссии, наблюдателей, СМИ</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Иные нарушения до дня голосования</div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="12" md="3" class="align-center">

                <v-text>
                    <div class="mt-8 font-weight-bold">
                        Нарушения до дня голосования
                    </div>
                </v-text>

                <v-checkbox color="info" class="mt-9">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение в оформлении участка</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения перед началом голосования: при опечатывании ящиков,
                            хранении и раздаче бюллетеней, неоглашение данных и пр.
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-3">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение прав наблюдателей, членов комиссии,
                            представителей СМИ: ограничение перемещения, запрет фотосъемки и пр.</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-3">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения при голосовании: вброс, голосование за других лиц,
                            незаконное включение в список избирателей и пр.</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-3">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения при голосовании «на дому»</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Невключение в список избирателей, непредоставление права голоса</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Принуждение избирателей, подвоз или нарушение тайны голосования</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Незаконная агитация, лотереи, подкуп</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Непринятие или нерассмотрение жалоб</div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info">
                    <template v-slot:label>
                        <div class="custom-label">Иные нарушения в день голосования</div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="12" md="3">

                <v-text>
                    <div class="mt-8 font-weight-bold">
                        Нарушения при подсчете голосов
                    </div>
                </v-text>

                <v-checkbox color="info" class="mt-3">
                    <template v-slot:label>
                        <div class="custom-label">Нарушение при подсчете голосов: совмещение этапов, неоглашение и
                            незанесение данных в увеличенную форму протокола, негласная сортировка и подсчет и пр.
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Искажения результатов: вброс, перекладывание из
                            пачки в пачку, переписывание результатов и пр.
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Негласное составление протокола, неправильная упаковка документов,
                            незаверение копии протокола и пр.
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Нарушения при обработке и установлении итогов в вышестоящих комиссиях
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Иные нарушения при подсчете и установлении итогов
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="12" md="3">

                <v-text>
                    <div class="mt-8 font-weight-bold">
                        Злоупотребления административным ресурсом
                    </div>
                </v-text>

                <v-checkbox color="info" class="mt-1">
                    <template v-slot:label>
                        <div class="custom-label">Неправомерные отказы при выдвижении и регистрации кандидатов
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Использование государственных и муниципальных СМИ для агитации без
                            оплаты из избирательного фонда
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Создание преимуществ для встреч с избирателями
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Давление начальства на избирателей
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Воздействие правоохранительных органов
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox color="info" class="mt-4">
                    <template v-slot:label>
                        <div class="custom-label">Иные виды использования административного ресурса в целях достижения
                            определенного результата на выборах
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-col cols="12" md="2">
            <div class="flex-column my-custom-button">
                <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                        <v-btn class="mt-4" rounded v-bind="activatorProps" color="info" text="Отправить сообщение"
                            variant="flat"></v-btn>
                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card title="Спасибо за ваше сообщение!">
                            <v-card-text>
                                <p>Оно появится на карте после обработки информации.</p>
                                <p class="mt-5">Вы можете <a class="custom-label" href="/sendMessages"
                                        target="_blank">отправить еще одно сообщение</a> отправить еще одно сообщение
                                    или
                                    ознакомится со <a class="custom-label" href="https://example.com"
                                        target="_blank">списком всех сообщений</a></p>
                            </v-card-text>
                            <v-card-text></v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text="Закрыть" @click="isActive.value = false"></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </v-col>

    </v-container>
</template>

<script>
export default {
    data() {
        return {
            selectedRegion: null,
            regions: [
                { title: 'Республика Адыгея (Адыгея)', value: 'Republic of Adygea', id: 1 },
                { title: 'Республика Алтай', value: 'Altai Republic', id: 2 },
                { title: 'Республика Башкортостан', value: 'Republic of Bashkortostan', id: 3 },
                { title: 'Республика Бурятия', value: 'The Republic of Buryatia', id: 4 },
                { title: 'Республика Дагестан', value: 'The Republic of Dagestan', id: 5 },
                { title: 'Республика Ингушетия', value: 'The Republic of Ingushetia', id: 6 },
                { title: 'Кабардино-Балкарская Республика', value: 'Kabardino-Balkarian Republic', id: 7 },
                { title: 'Республика Калмыкия', value: 'Republic of Kalmykia', id: 8 },
                { title: 'Карачаево-Черкесская Республика', value: 'Karachay-Cherkess Republic', id: 9 },
                { title: 'Республика Карелия', value: 'Republic of Karelia', id: 10 },
                { title: 'Республика Коми', value: 'Komi Republic', id: 11 },
                { title: 'Республика Крым', value: 'Republic of Crimea', id: 12 },
                { title: 'Республика Марий Эл', value: 'Mari El Republic', id: 13 },
                { title: 'Республика Мордовия', value: 'The Republic of Mordovia', id: 14 },
                { title: 'Республика Саха (Якутия)', value: 'Saha Republic', id: 15 },
                { title: 'Республика Северная Осетия-Алания', value: 'Republic of North Ossetia-Alania', id: 16 },
                { title: 'Республика Татарстан (Татарстан)', value: 'Republic of Tatarstan', id: 17 },
                { title: 'Республика Тыва', value: 'Tyva Republic', id: 18 },
                { title: 'Удмуртская Республика', value: 'Udmurt republic', id: 19 },
                { title: 'Чеченская Республика', value: 'Chechen Republic', id: 20 },
                { title: 'Чувашская Республика - Чувашия', value: 'Chuvash Republic - Chuvashia', id: 21 },
                { title: 'Алтайский край', value: 'Altai region', id: 22 },
                { title: 'Забайкальский край', value: 'Transbaikal region', id: 23 },
                { title: 'Камчатский край', value: 'Kamchatka Krai', id: 24 },
                { title: 'Краснодарский край', value: 'Krasnodar region', id: 25 },
                { title: 'Красноярский край', value: 'Krasnoyarsk region', id: 26 },
                { title: 'Пермский край', value: 'Perm region', id: 27 },
                { title: 'Приморский край', value: 'Primorsky Krai', id: 28 },
                { title: 'Ставропольский край', value: 'Stavropol region', id: 29 },
                { title: 'Хабаровский край', value: 'Khabarovsk region', id: 30 },
                { title: 'Амурская область', value: 'Amur region', id: 31 },
                { title: 'Архангельская область', value: 'Arhangelsk region', id: 32 },
                { title: 'Астраханская область', value: 'Astrakhan region', id: 33 },
                { title: 'Белгородская область', value: 'Belgorod region', id: 34 },
                { title: 'Брянская область', value: 'Bryansk region', id: 35 },
                { title: 'Владимирская область', value: 'Vladimir region', id: 36 },
                { title: 'Волгоградская область', value: 'Volgograd region', id: 37 },
                { title: 'Вологодская область', value: 'Vologda Region', id: 38 },
                { title: 'Воронежская область', value: 'Voronezh region', id: 40 },
                { title: 'Ивановская область', value: 'Ivanovo region', id: 41 },
                { title: 'Иркутская область', value: 'Irkutsk region', id: 42 },
                { title: 'Калининградская область', value: 'Kaliningrad region', id: 43 },
                { title: 'Калужская область', value: 'Kaluga region', id: 44 },
                { title: 'Кемеровская область', value: 'Kemerovo region', id: 45 },
                { title: 'Кировская область', value: 'Kirov region', id: 46 },
                { title: 'Костромская область', value: 'Kostroma region', id: 47 },
                { title: 'Курганская область', value: 'Kurgan region', id: 48 },
                { title: 'Курская область', value: 'Kursk region', id: 49 },
                { title: 'Ленинградская область', value: 'Leningrad region', id: 50 },
                { title: 'Липецкая область', value: 'Lipetsk region', id: 51 },
                { title: 'Магаданская область', value: 'Magadan Region', id: 52 },
                { title: 'Московская область', value: 'Moscow region', id: 53 },
                { title: 'Мурманская область', value: 'Murmansk region', id: 54 },
                { title: 'Нижегородская область', value: 'Nizhny Novgorod Region', id: 55 },
                { title: 'Новгородская область', value: 'Novgorod region', id: 56 },
                { title: 'Новосибирская область', value: 'Novosibirsk region', id: 57 },
                { title: 'Омская область', value: 'Omsk region', id: 58 },
                { title: 'Орловская область', value: 'Oryol Region', id: 59 },
                { title: 'Пензенская область', value: 'Penza region', id: 60 },
                { title: 'Псковская область', value: 'Pskov region', id: 61 },
                { title: 'Ростовская область', value: 'Rostov region', id: 62 },
                { title: 'Рязанская область', value: 'Ryazan Oblast', id: 63 },
                { title: 'Самарская область', value: 'Samara Region', id: 64 },
                { title: 'Саратовская область', value: 'Saratov region', id: 65 },
                { title: 'Сахалинская область', value: 'Sakhalin region', id: 66 },
                { title: 'Свердловская область', value: 'Sverdlovsk region', id: 67 },
                { title: 'Смоленская область', value: 'Smolensk region', id: 68 },
                { title: 'Тамбовская область', value: 'Tambov Region', id: 69 },
                { title: 'Тверская область', value: 'Tver region', id: 70 },
                { title: 'Томская область', value: 'Tomsk region', id: 71 },
                { title: 'Тульская область', value: 'Tula region', id: 72 },
                { title: 'Тюменская область', value: 'Tyumen region', id: 73 },
                { title: 'Ульяновская область', value: 'Ulyanovsk region', id: 74 },
                { title: 'Челябинская область', value: 'Chelyabinsk region', id: 75 },
                { title: 'Ярославская область', value: 'Yaroslavl region', id: 76 },
                { title: 'город Санкт-Петербург', value: 'city of St. Petersburg', id: 77 },
                { title: 'город Севастополь', value: 'city of Sevastopol', id: 78 },
                { title: 'Еврейская автономная область', value: 'Jewish Autonomous Region', id: 79 },
                { title: 'Ненецкий автономный округ', value: 'Nenets Autonomous Okrug', id: 80 },
                { title: 'Ханты-Мансийский автономный округ', value: 'Khanty-Mansiysk Autonomous Okrug', id: 81 },
                { title: 'Чукотский автономный округ', value: 'Chukchi Autonomous Okrug', id: 82 },
                { title: 'Ямало-Ненецкий автономный округ', value: 'Yamalo-Nenets Autonomous Okrug', id: 82 },

            ]
        };
    },
    methods: {
        async submitSelection() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/messages`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedRegionId: this.selectedRegion.value }), // Отправляем id выбранной опции
                });

                if (!response.ok) {
                    throw new Error('Сетевая ошибка при отправке данных');
                }

                // Обработка успешного ответа
                console.log('Выбор успешно отправлен, id:', this.selectedRegion.value);
            } catch (error) {
                console.error('Ошибка при отправке выбора:', error);
            }
        }
    },
};
</script>

<style scoped>
.text-message__all {
    font-size: clamp(13px, 2vw, 18px);
}

::v-deep .v-input__details {
    min-height: 0px;
    padding-top: 0px;
}


::v-deep .v-selection-control .v-label {
    font-size: clamp(11px, 2vw, 15px);
    opacity: 1;
    display: contents !important;
}
@media (max-width: 995px) and (min-width: 960px) {
   .my-col {
     font-size: 17px;
    }
}
</style>
