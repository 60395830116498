<template>
  <div>
    <v-container>
      <!-- Заголовок -->
      <div>
        <v-sheet class="pa-2 mb-6 page-background ma-4" :elevation="0">
          <v-row align="center" justify="start">
            <v-col class="d-flex" cols="8">
              <div>
                <v-img :src="require('@/assets/img/logo.png')" height="35" width="100"></v-img>
              </div>
              <v-btn rounded class="pa-0 ma-0 header-btn" variant="text" color="info">
                Личный кабинет <br> Статистика сообщений
              </v-btn>
            </v-col>

            <v-col class="d-flex display-1 justify-end">
              <template v-if="isAuthenticated()">
                <span class="header-btn__account">{{ user.name }}</span>
                <v-btn color="info" class="ml-2 header-btn" @click="logout" prepend-icon="mdi mdi-logout" rounded variant="text" stacked height="40">
                  Выйти
                </v-btn>
              </template>
              <template v-else>
                <v-btn color="info" to="/authorization" class="header-btn"
                       prepend-icon="mdi mdi-account-circle-outline" rounded variant="text" stacked height="40">
                  Вход
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
      <!-- Поиск и фильтры -->
      <UserSearch></UserSearch>
      <template v-if="isAuthenticated()">
        <UserAllFilter @apply-filters="handleApplyFilters" />
        <UserFilter @filterByStatus="handleFilterByStatus" ref="userFilterComponent"/>
      </template>
      <!-- Список сообщений -->
    </v-container>

    <v-container>
      <UserMessage ref="userMessagesComponent" />
    </v-container>

  </div>
</template>

<script>
import { useAuth } from '@/composables/useAuth';
import UserAllFilter from '@/components/UserAllFilter.vue';
import UserFilter from '@/components/UserFilter.vue';
import UserSearch from '@/components/UserSearch.vue';
import UserMessage from '@/components/UserMessage.vue';
import { ref, reactive } from 'vue';


export default {
  components: {
    UserFilter,
    UserSearch,
    UserMessage,
    UserAllFilter,
  },
  setup() {
    const { isAuthenticated, user, logout } = useAuth();
    const userMessagesComponent = ref(null);
    const userFilterComponent = ref(null);
    const filters = reactive({});


    function handleFilterByStatus(status) {
      if (userMessagesComponent.value) {
        userMessagesComponent.value.fetchMessages(status, null, 1);
      }
    }

    function handleApplyFilters(newFilters) {
      Object.assign(filters, newFilters); // Обновляем текущее состояние фильтров
      if (userMessagesComponent.value) {
        userMessagesComponent.value.fetchMessages(null, filters, 1);
        userFilterComponent.value.fetchMessageCounts();
      }
    }

    return {
      handleFilterByStatus,
      handleApplyFilters,
      userFilterComponent,
      filters,
      userMessagesComponent,
      isAuthenticated,
      user,
      logout,
    };
  },
};
</script>

<style scoped>
::v-deep .header-btn .v-btn__content {
font-size: clamp(4px, 2vw, 15px);

}
</style>