<template>
  <v-container class="fill-height page-background" fluid>
    <v-row>
      <v-col class="authorization-object">
        <v-card-title class="text-center">
          <div class="mb-10">
            <v-img :src="require('@/assets/img/logo.png')" height="45"></v-img>
          </div>
          <v-card-text class="font-weight-bold text-logo__auth">
            Независимый общественный <br> мониторинг
          </v-card-text>
        </v-card-title>
      </v-col>
      <v-col>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-sheet class="mx-auto" width="300">
              <v-form ref="form" @submit.prevent="submitForm">
                <v-text-field v-model="name" :rules="[v => !!v || 'Логин обязателен']" label="Логин" required></v-text-field>
                <v-text-field v-model="password" :rules="[v => !!v || 'Пароль обязателен']" label="Пароль" type="password" required></v-text-field>
                <v-btn rounded class="mt-4" color="primary" block type="submit">
                  Войти
                </v-btn>
              </v-form>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@/composables/useAuth';

export default {
  setup() {
    const name = ref('');
    const password = ref('');
    const router = useRouter();
    const { authenticateUser } = useAuth(); // Используйте функцию из useAuth

    const submitForm = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/login_check`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: name.value,
            password: password.value,
          }),
        });

        if (!response.ok) {
          throw new Error('Ошибка авторизации');
        }

        const data = await response.json();

        authenticateUser(data.token, { name: data.username }, data.cfr, data.regionIso);

        router.push('/');
      } catch (error) {
        console.error('Ошибка при отправке формы', error);
        // Обработка ошибок авторизации
      }
    };

    return {
      name,
      password,
      submitForm,
    };
  },
};

</script>

<style scoped>
.page-background {
  background-image: url('@/assets/img/bg-3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-text__size {
    font-size: clamp(15px, 2rem, 20px);
}

.autorization-object {
  margin-bottom: 150px;
}

.custom-label {
  font-size: 0.7rem;
  margin-right: 12px;
}

.my-custom-button {
  padding: 4px 8px;
  font-size: 0.875rem;
  width: 50%;
  margin-left: 70px;
}

::v-deep .v-sheet {
  background-color: rgb(0 0 0 / 0%)
}

.text-logo__auth {
    font-size: clamp(10px, 2rem, 18px)!important;
}
</style>